$(document).ready(function() {

    //
    // Große-Lightbox (Bildergalerie)
    //
    function getForGrossLightboxActivePictureCount(Id){

        var ActivePicture = $('div#slickTrack-' + Id).find('div.slick-active').first().find('img');
        var ActivePictureCount = ActivePicture.attr('data-count');

        return ActivePictureCount;

    }

    function getForSourceSliderActivePictureCount(Id){

        var ActivePicture = $('div#grossSlickTrack-' + Id).find('div.slick-active').first().find('img');
        var ActivePictureCount = ActivePicture.attr('data-count');

        return ActivePictureCount;

    }

    function setGrossLightbox(){

        // Lightbox öffnen
        $( ".bund-open-grosslightbox" ).each(function() {

            $(this).on("click keypress", function (event) {

                if (event.type === "click" || event.type === "keypress") {
                    event.preventDefault();
                    //console.log('tastaturclick ' + event.which);

                    if (event.which == 32 || event.which == 13 || event.which == 1) {
                        event.preventDefault();

                        var Button = $(this);
                        var Id = Button.attr('data-uid');
                        var Modal = $('div#grosslightbox-' + Id);
                        var ModalHeader = $('div#grosslightbox-h' + Id);
                        var ModalCloseButton = $('button#grosslightbox-close-lightbox-c' + Id);
                        var Body = $('body');

                        // Modal anzeigen
                        if(Modal.hasClass('hidden')){
                            Modal.removeClass('hidden');
                            Body.addClass('overflow-y-hidden');
                            //var PosY = $(document).scrollTop(); //Scrollposition
                            ModalHeader.focus();
                            var ActivePictureCount = getForGrossLightboxActivePictureCount(Id);
                            initGrossBildergalerie(ActivePictureCount);
                        }
                    }
                }

            });
        });

        // Lightbox mit Button schließen
        $( ".close-grosslightbox-button" ).each(function() {

            $(this).on("click", function (event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var CloseButton = $(this);
                    var CloseButtonId = CloseButton.attr('data-uid');
                    var CloseButtonModal = $('div#grosslightbox-' + CloseButtonId);
                    var CloseButtonBody = $('body');
                    var OpenButton = $('button#open-grosslightbox-' + CloseButtonId);
                    var SourceSlider = $('div#c' + CloseButtonId + ' .bund-carousel');

                    // Modal schließen
                    if(CloseButtonModal.hasClass('hidden')===false){
                        CloseButtonModal.addClass('hidden');
                        CloseButtonBody.removeClass('overflow-y-hidden');
                        //$('html, body').scrollTop(PosY); //Scrollposition wieder herstellen
                        OpenButton.focus();

                        var ActivePictureCount = getForSourceSliderActivePictureCount(CloseButtonId);
                        var ActivePictureCountInt = parseInt(ActivePictureCount);
                        var ActivePictureCountInt = ActivePictureCountInt - 1;

                        SourceSlider.slick('slickGoTo', ActivePictureCountInt, true);

                        destroyGrossBildergalerie();
                    }
                }
            });
        });

        // Lightbox mit ESC schließen
        $(document).keydown(function(eventa) {

            var ModalDialoge = $('div.grosslightbox-modal');
            var Body = $('body');

            ModalDialoge.each(function() {

                var Modal = $(this);
                var Id = Modal.attr('data-uid');
                var OpenButton = $('button#open-grosslightbox-' + Id);
                var SourceSlider = $('div#c' + Id + ' .bund-carousel');

                if(Modal.hasClass('hidden')===false){
                    // 27=ESC
                    if (eventa.keyCode == 27) {
                        Modal.addClass('hidden');
                        Body.removeClass('overflow-y-hidden');
                        OpenButton.focus();

                        var ActivePictureCount = getForSourceSliderActivePictureCount(Id);
                        var ActivePictureCountInt = parseInt(ActivePictureCount);
                        var ActivePictureCountInt = ActivePictureCountInt - 1;

                        SourceSlider.slick('slickGoTo', ActivePictureCountInt, true);

                        destroyGrossBildergalerie();
                    }
                }

            });

        });

        // Tab
        $(document).keydown(function(eventl) {

            var ModalDialoge = $('div.grosslightbox-modal');

            ModalDialoge.each(function () {

                var Modal = $(this);
                var Id = Modal.attr('data-uid');
                var LastModalTabLink = Modal.find('button#grosslightbox-close-lightbox-c' + Id);
                var FirstModalTabLink = Modal.find('div#grosslightbox-h' + Id);
                var RasterTabLink = Modal.find('div#grosslightbox-raster' + Id).find('a').first();
                var SlickButtons = Modal.find('div.slick-arrow button');


                // Letztes Element - springe von letztem Tab-Element im Modal, zu erstem Tab-Element im Modal
                LastModalTabLink.keydown(function(eventlforwardtab) {
                    // Tab vor - springe zum Header
                    if (!eventlforwardtab.shiftKey && eventlforwardtab.keyCode == 9) {
                        eventlforwardtab.preventDefault();
                        FirstModalTabLink.focus();
                    }
                });

                // Erstes Element
                FirstModalTabLink.keydown(function(eventlbackwardtab) {
                    // Tab zurück - springe zu CloseButton
                    if (eventlbackwardtab.shiftKey && eventlbackwardtab.keyCode == 9) {
                        eventlbackwardtab.preventDefault();
                        LastModalTabLink.focus();
                    }
                });

                // Fallback wenn nichts fokussiert ist
                Modal.keydown(function(eventltab) {
                    if (Modal.is(':active')) {
                        if (eventltab.keyCode == 9) {
                            eventltab.preventDefault();
                            FirstModalTabLink.focus();
                        }
                    }
                });
            });

        });

        // Zur Rasteransicht wechseln
        $( ".gotoraster-grosslightbox-button" ).each(function() {

            var RasterButton = $(this);
            var Id = RasterButton.attr('data-uid');
            var Modal = $('div#grosslightbox-' + Id);
            var Body = $('body');
            var ActivePictureCount = getForSourceSliderActivePictureCount(Id);
            var Raster = $('div#rasterlightbox-' + Id);
            var RasterHeader = $('div#rasterlightbox-h' + Id);
            var SourceSlider = $('div#c' + Id + ' .bund-carousel');

            RasterButton.on('click keypress', function(event) {
                if (event.type === "click" || event.type === "keypress") {
                    event.preventDefault();
                    // 32=Leertaste, 13=Return, 1=Linke Maustaste
                    if (event.which == 32 || event.which == 13 || event.which == 1) {

                        // Id aktives Bild speichern
                        var ActivePicture = $('div#grosslightbox-' + Id + ' .bund-gross-carousel > .slick-list > .slick-track').find('div.slick-active').first().find('img');
                        var ActivePictureCount = ActivePicture.attr('data-count');

                        // Grossansicht schließen
                        Modal.addClass('hidden');
                        destroyGrossBildergalerie();

                        // Rasteransicht öffnen
                        Raster.removeClass('hidden');
                        RasterHeader.focus();

                        setActivePicture(ActivePictureCount, Raster); // die Funktion befindet sich inline im Modal (wegen den Übersetzungen)
                        SourceSlider.slick('slickGoTo', (ActivePictureCount - 1), true);

                    }
                }

            });

        });

    }
    setGrossLightbox();

});